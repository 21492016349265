import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Select,
  useList, useLocalStorage,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalStickerPrint(props) {
  // ----------------------------------------------------------------------------------------------------
  const [localSettings, setLocalSettings] = useLocalStorage(
    "printer",
    {
      printerUuid: null,
    }
  );
  const [printerUuid, setPrinterUuid] = useState(
    localSettings.printerUuid || null
  );
  useEffect(() => {
    setLocalSettings({
      printerUuid,
    });
  }, [
    printerUuid,
  ]);
  // ----------------------------------------------------------------------------------------------------

  const inputPrinter = useRef(null);
  const inputNumberCopies = useRef(null);

  const inputManufactureDate = useRef(null);
  const inputValue = useRef(null);

  const {notification} = useNotification();

  const {
    list: [printers],
  } = useList({
    apiUrl: "/api/printers/list",
    rootUrl: ROOT_URL,
  });

  const {
    object: [nomenclature],
  } = useObject({
    apiUrl: "/api/nomenclature/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const changeDownloadPDF = () => {
    const manufactureDate = jsonFormatText(inputManufactureDate.current.value);

    if (isValidateNullText(manufactureDate)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    let v = 1;

    if (!nomenclature.is_pcs) {
      const value = inputValue.current.value;
      v = Number(Number(value).toFixed(3));
    }

    const jsonData = JSON.stringify({
      manufacture_date: (!!manufactureDate && manufactureDate + ":00Z") || null,
      value: v,
    });

    axios
      .post("/api/nomenclature/" + props.object.uuid + "/export/print/sticker/pdf",
        jsonData,
        {
          responseType: "arraybuffer",
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "№" + props.object.id + (!!props.object.name && '_' + props.object.name) + ".pdf"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changePrint = () => {
    const numberCopies = jsonFormatText(inputNumberCopies.current.value);
    const printerUuid = jsonFormatText(inputPrinter.current.value);
    const manufactureDate = jsonFormatText(inputManufactureDate.current.value);

    if (isValidateNullText(numberCopies) || Number(numberCopies) < 1) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    if (isValidateNullText(printerUuid) || printerUuid === 'null') {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    if (isValidateNullText(manufactureDate)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    let v = 1;

    if (!nomenclature.is_pcs) {
      const value = inputValue.current.value;
      v = Number(Number(value).toFixed(3));
    }

    const jsonData = JSON.stringify({
      manufacture_date: (!!manufactureDate && manufactureDate + ":00Z") || null,
      value: v,
      number_copies: Number(numberCopies),
      printer: !!printerUuid && printerUuid.length > 0 && printerUuid !== 'null' ? {
        uuid: printerUuid,
      } : null,
    });

    axios
      .post("/api/nomenclature/" + props.object.uuid + "/sticker/print", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Отправлено на печать", "blue");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getPrinterSelectOptions = (list) => {
    const options = [
      // {
      //   value: "null",
      //   title: "Не выбран",
      // },
    ];

    list.map((item) => {
      const option = {
        value: item.uuid,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!nomenclature && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          <div className="Form">
            <div className="Form__title">
              Этикетка: {nomenclature.name}
              <div className="Text__signature">Печать</div>
            </div>

            <div className="Form__field">
              <Input
                type={'datetime-local'}
                label="Дата производства *"
                value={moment().format("YYYY-MM-DD HH:mm")}
                reference={inputManufactureDate}
              />
            </div>

            {!nomenclature.is_pcs && (
              <div className="Form__field">
                <Input reference={inputValue} type={'number'} label={"Размер (" +
                  (!!nomenclature.measurement_unit_type ? nomenclature.measurement_unit_type.designation : "-") + ") *"}
                       value={
                         (Number(((!!nomenclature.value && !!nomenclature.measurement_unit_type ?
                           (nomenclature.value / nomenclature.measurement_unit_type.ratio) : !!nomenclature.value && nomenclature.value)).toFixed(3))) || null
                       }
                />
              </div>
            )}

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue"
                      onClick={(event) => changeDownloadPDF()}>
                Скачать PDF
              </button>
            </div>

            <div className="Form__field" style={{
              marginTop: 16
            }}>
              <Select
                isFixed={true}
                options={getPrinterSelectOptions(printers)}
                label="Принтер"
                reference={inputPrinter}
                onChange={(event) => setPrinterUuid(event.target.value)}
                value={!!printerUuid && printerUuid}
              />
            </div>

            <div className="Form__field Form__field_last">
              <Input
                reference={inputNumberCopies}
                type={'number'}
                label="Количество копий *"
                value={1}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue"
                      onClick={(event) => changePrint()}>
                Печать
              </button>
            </div>
          </div>
        </Modal>
      </>
    )
  );
}

export default ModalStickerPrint;
