import React, {useEffect, useRef, useState} from "react";
import "./ScannerInput.css";

function containsSpecificSequence(text) {
  const sequence = "УТВЖЗСФКВ"; // Искомая последовательность (END:PCARD)
  return text.includes(sequence);
}

// Функция для замены букв, написанных с русской раскладкой, на английские
function convertToEnglish(inputText) {
// Маппинг русских символов на английские для раскладки QWERTY (маленькие и заглавные буквы)
  const ruToEnMap = {
    'Й': 'Q', 'Ц': 'W', 'У': 'E', 'К': 'R', 'Е': 'T', 'Н': 'Y', 'Г': 'U', 'Ш': 'I', 'Щ': 'O', 'З': 'P',
    'Х': '{', 'Ъ': '}', 'Ф': 'A', 'Ы': 'S', 'В': 'D', 'А': 'F', 'П': 'G', 'Р': 'H', 'О': 'J', 'Л': 'K',
    'Д': 'L', 'Ж': ':', 'Э': '"', '/': '|', 'Я': 'Z', 'Ч': 'X', 'С': 'C', 'М': 'V', 'И': 'B', 'Т': 'N',
    'Ь': 'M', 'Б': '<', 'Ю': '>', ',': '?',

    'й': 'q', 'ц': 'w', 'у': 'e', 'к': 'r', 'е': 't', 'н': 'y', 'г': 'u', 'ш': 'i', 'щ': 'o', 'з': 'p',
    'х': '[', 'ъ': ']', 'ф': 'a', 'ы': 's', 'в': 'd', 'а': 'f', 'п': 'g', 'р': 'h', 'о': 'j', 'л': 'k',
    'д': 'l', 'ж': ';', 'я': 'z', 'ч': 'x', 'с': 'c', 'м': 'v', 'и': 'b', 'т': 'n',
    'ь': 'm', 'б': ',', 'ю': '.', '.': '/',

    '?': '&',
  };

  let outputText = '';

  // Проходим по каждому символу в строке
  for (let i = 0; i < inputText.length; i++) {
    const char = inputText[i];
    // Заменяем символ, если он есть в маппинге
    outputText += ruToEnMap[char] || char; // Если символ не найден в маппинге, оставляем его как есть
  }

  return outputText;
}

function cleanPCARDData(data) {
  const startMarker = "BEGIN:PCARD";
  const endMarker = "END:PCARD";

  // Находим индекс начала и конца
  const startIndex = data.indexOf(startMarker);
  const endIndex = data.indexOf(endMarker);

  // Если оба маркера найдены, извлекаем строку между ними
  if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
    return data.slice(startIndex, endIndex + endMarker.length);
  }

  // Если маркеры не найдены, возвращаем пустую строку или исходную строку
  return '';
}

const ScannerInput = ({onScan, isChangeScan, isAutoScan, isFocusDisable}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(true);


  useEffect(() => {
    // Устанавливаем фокус при монтировании
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    // Таймер для автоматического возврата фокуса
    if (isAutoScan) {
      const interval = setInterval(() => {
        if (!isFocused && inputRef.current) {
          inputRef.current.focus();
        }
      }, 300);

      return () => clearInterval(interval);
    }
  }, [isFocused, isAutoScan]);

  const handleChange = (e) => {
    let newValue = "";
    if (!containsSpecificSequence(e.target.value)) {
      newValue = e.target.value;
    } else {
      newValue = convertToEnglish(e.target.value);
    }

    if (onScan && newValue.endsWith("END:PCARD")) {
      onScan(cleanPCARDData(newValue.trim()));
      setValue("");
    } else {
      setValue(newValue);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      setValue("");
    }
  };

  useEffect(() => {
    if (!isFocusDisable && inputRef.current) {
      inputRef.current.focus();
    }
    if (isFocusDisable && inputRef.current) {
      inputRef.current.blur();
    }
  }, [isChangeScan, isFocusDisable])

  return (
    <div className="scanner-input"
         onClick={handleButtonClick}
         style={{
           cursor: "pointer",
           position: "relative",
           height: 68
         }}>
      <div style={{
        pointerEvents: "none",
        position: "absolute",
        fontSize: 17,
        fontWeight: 600,
        color: isFocused || isAutoScan ? "#0061ff" : "#202020"
      }}>
        {(value.length < 2 && ((isFocused || isAutoScan) ? "Сканируйте код..." : "Нажмите, чтобы продолжить сканирование"))}
      </div>
      <textarea
        style={{
          borderColor: isFocused || isAutoScan ? "#0061ff20" : "#202020",
          height: 68
        }}
        ref={inputRef}
        value={value.trim()}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="scanner-input-field"
        // placeholder={isFocused || isAutoScan ? "Сканируйте код..." : "Нажмите, чтобы продолжить сканирование"}
      />
    </div>
  );
};

export default ScannerInput;
