import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import {getErrorMessageByKey, Input, Modal, useList, useNotification, useObject,} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ModalOrderItemSelect from "./ModalOrderItemSelect";
import styleTumbler from "../../../../styles/modules/Tumbler.module.scss";

export const ModalAddOrderItem2 = (props) => {
  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)

  const inputValue = useRef(null);
  const inputQuantity = useRef(null);

  const {notification} = useNotification();

  const [tumbler, setTumbler] = useState("ac");

  const {
    list: [list],
  } = useList({
    apiUrl: "api/nomenclatures/list",
    rootUrl: ROOT_URL,
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/realization-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
  });


  const quantityConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(6)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    if (!selectItem) {
      notification("Укажите номенклатуру", "red");
      return;
    }

    let quantity;

    if (tumbler === "aa") {
      const value = inputValue.current.value;
      const v = Number((Number(1).toFixed(6) * (!!selectItem.value && !!selectItem.measurement_unit_type ?
        (selectItem.value / selectItem.measurement_unit_type.ratio) : !!selectItem.value && selectItem.value)).toFixed(3))
      quantity = Number(Number(value) / v).toFixed(6)
    } else if (tumbler === "ac") {
      quantity = inputQuantity.current.value
    }

    const jsonData = JSON.stringify({
      nomenclature: {
        uuid: selectItem.uuid,
      },
      quantity: quantityConvert(quantity),
    });

    axios
      .post("/api/realization-order/" + props.object.uuid + "/item/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Позиция добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!list &&
    !!object && (
      <>
        <Modal handleClose={() => null}>
          <form className="Form" onSubmit={(event) => changeAdd(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Добавить позицию на реализацию</div>
            </div>

            {!selectItem && (
              <div className="Form__field Form__field_last"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Номенклатура
                </div>
                <div className="Field__text">
                  -
                </div>
              </div>
            )}

            {!!selectItem && (
              <div className="Form__field"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Номенклатура
                </div>
                <div className="Field__text">
                  {selectItem.id + ". " + selectItem.name + (!!selectItem.article ? " - " + selectItem.article : "")}
                </div>
              </div>
            )}

            {!!selectItem && (
            <div className="Form__field">
              <ul
                className={styleTumbler._}
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <li className={styleTumbler._link}>
                  <a
                    className={
                      styleTumbler._text +
                      ((!!tumbler &&
                          tumbler === "ac" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={() => setTumbler("ac")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Количество
                  </a>
                </li>
                {!selectItem.is_pcs && (
                  <li className={styleTumbler._link}>
                    <a
                      className={
                        styleTumbler._text +
                        ((!!tumbler &&
                            tumbler === "aa" &&
                            " " + styleTumbler.active + " ") ||
                          "")
                      }
                      onClick={() => setTumbler("aa")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Размер
                    </a>
                  </li>
                )}
              </ul>
            </div>
            )}

            {!!selectItem && !!tumbler && tumbler === "aa" && (
              <div className="Form__field">
                <Input reference={inputValue} type={'number'} label={"Размер (" +
                  (!!selectItem.measurement_unit_type ? selectItem.measurement_unit_type.designation : "-") + ") *"}
                       value={
                         (Number(((!!selectItem.value && !!selectItem.measurement_unit_type ?
                           (selectItem.value / selectItem.measurement_unit_type.ratio) : !!selectItem.value && selectItem.value)).toFixed(3))) || null
                       }
                />
              </div>
            )}

            {!!selectItem && !!tumbler && tumbler === "ac" && (
              <div className="Form__field">
                <Input reference={inputQuantity} type={'number'} label="Количество *"
                       value={1}/>
              </div>
            )}

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Добавить
              </button>
            </div>
            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => props.handleClose()}>
                Закрыть
              </div>
            </div>
          </form>
        </Modal>

        {isModalSelectItem && (
          <ModalOrderItemSelect
            item={selectItem}
            successfulRequest={item =>
              setSelectItem(item)
            }
            handleClose={() => setIsModalSelectItem(false)}
          />
        )}
      </>
    )
  );
};
