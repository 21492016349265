import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  Select,
  Textarea,
  useNotification,
} from "@vokymlak/kabinet-ui";

function ModalPrinterAdd(props) {
  const inputName = useRef(null);

  const {notification} = useNotification();

  const changeAdd = () => {
    const name = jsonFormatText(inputName.current.value);

    const jsonData = JSON.stringify({
      name: name,
    });

    axios
      .post("/api/printer/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Принтер добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <>
      <Modal handleClose={() => props.handleClose()}>
        <div className="Form">
          <div className="Form__title">
            Новый
            <div className="Text__signature">Принтер</div>
          </div>

          <div className="Form__field">
            <Input reference={inputName} label="Наименование *"/>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue"
                    onClick={() => changeAdd()}>
              Добавить
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalPrinterAdd;
