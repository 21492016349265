import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";

function ModalPrinterEdit(props) {
  const inputName = useRef(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/printer/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: response => {},
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const changeEdit = () => {
    const name = jsonFormatText(inputName.current.value);

    const jsonData = JSON.stringify({
      name: name,
    });

    axios
      .put("/api/printer/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/printer/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Принтер удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          {!isDelete && (
            <div className="Form">
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {!!object.name && object.name}
                <div className="Text__signature">Принтер</div>
              </div>

              <div className="Form__field">
                <Input reference={inputName} label="Наименование *"
                       value={!!object.name && object.name}/>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_blue"
                        onClick={() => changeEdit()}>
                  Применить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div
                  className="Action__link Action__link_red"
                  onClick={() => setIsDelete(true)}
                >
                  Удалить
                </div>
              </div>
            </div>
          )}
          {isDelete && (
            <form className="Form" onSubmit={(event) => changeDelete(event)}>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {!!object.name && object.name}
                <div className="Text__signature">Удалить</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_red">
                  Удалить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div className="Action__link" onClick={() => setIsDelete(false)}>
                  Отмена
                </div>
              </div>
            </form>
          )}
        </Modal>
      </>
    )
  );
}

export default ModalPrinterEdit;
