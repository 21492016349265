import React from "react";
import {NavLink} from "react-router-dom";
import {isProfileRoleTypeTags, useProfile,} from "@vokymlak/kabinet-ui";
import {ROLE_ADMINISTRATOR, ROLE_DIRECTOR, ROLE_MANAGER,} from "../../config/constants";

function MenuRouting(props) {
  const {profile} = useProfile();

  const onChange = () => {
    if (!!props.onChange) {
      props.onChange()
    }
  }

  return (
    <>
      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/realization-orders"} onClick={() => onChange()}>
              Реализация
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/income-orders"} onClick={() => onChange()}>
              Поступление
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/production-orders"}
              onClick={() => onChange()}
            >
              Производство
            </NavLink>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/boning-orders"}
              onClick={() => onChange()}
            >
              Обвалка
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/contractors"} onClick={() => onChange()}>
              Контрагенты
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
          <ul className="Menu__list Menu__list_box">
            <li className="List__link">
              <NavLink className={"Link__text"} to={"/branches"} onClick={() => onChange()}>
                Филиалы
              </NavLink>
            </li>
          </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Сайт</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/site-shop-orders"}
              onClick={() => onChange()}
            >
              Заказы по рознице
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
        <>
          {/*<ul className="Menu__list Menu__list_box">*/}
          {/*  <li className="List__link">*/}
          {/*    <NavLink*/}
          {/*      className={"Link__text"}*/}
          {/*      to={"/orders"}*/}
          {/*      onClick={() => onChange()}*/}
          {/*    >*/}
          {/*      Заказы*/}
          {/*    </NavLink>*/}
          {/*  </li>*/}
          {/*</ul>*/}

          <ul className="Menu__list Menu__list_box">
            <li className="List__header">
              <div className="Header__text">Номенклатура</div>
            </li>
            <li className="List__link" style={{paddingTop: 12}}>
              <NavLink
                className={"Link__text"}
                to={"/all-nomenclatures"}
                onClick={() => onChange()}
              >
                Вся номенклатура
              </NavLink>
            </li>
            <li className="List__link">
              <NavLink
                className={"Link__text"}
                to={"/nomenclatures"}
                onClick={() => onChange()}
              >
                Номенклатура
              </NavLink>
            </li>
          </ul>

          <ul className="Menu__list Menu__list_box">
            <li className="List__link">
              <NavLink
                className={"Link__text"}
                to={"/plan-warehouse"}
                onClick={() => onChange()}
              >
                План на сырьё
              </NavLink>
            </li>
          </ul>

          <ul className="Menu__list Menu__list_box">
            <li className="List__link">
              <NavLink
                className={"Link__text"}
                to={"/cows"}
                onClick={() => onChange()}
              >
                КРС
              </NavLink>
            </li>
          </ul>
        </>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          {/*<li className="List__link">*/}
          {/*  <NavLink className={"Link__text"} to={"/clients"} onClick={() => onChange()}>*/}
          {/*    Клиенты*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/employees"} onClick={() => onChange()}>
              Сотрудники
            </NavLink>
          </li>
          {isProfileRoleTypeTags(profile, [
            ROLE_ADMINISTRATOR,
            ROLE_DIRECTOR,
          ]) && (
            <>
              <li className="List__link">
                <NavLink className={"Link__text"} to={"/directors"} onClick={() => onChange()}>
                  Руководители
                </NavLink>
              </li>
            </>
          )}
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Печать</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/printers"}
              onClick={() => onChange()}
            >
              Принтеры
            </NavLink>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/printer-files"}
              onClick={() => onChange()}
            >
              Очередь печати
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/accounts"} onClick={() => onChange()}>
              Аккаунты
            </NavLink>
          </li>
        </ul>
      )}

      {/*{isProfileRoleTypeTags(profile, [*/}
      {/*  ROLE_ADMINISTRATOR,*/}
      {/*  ROLE_DIRECTOR,*/}
      {/*  ROLE_MANAGER,*/}
      {/*]) && (*/}
      {/*  <ul className="Menu__list Menu__list_box">*/}
      {/*    <li className="List__link">*/}
      {/*      <NavLink*/}
      {/*        className={"Link__text"}*/}
      {/*        to={"/tickets"}*/}
      {/*        onClick={() => onChange()}*/}
      {/*      >*/}
      {/*        Тикеты*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*    <li className="List__link">*/}
      {/*      <NavLink*/}
      {/*        className={"Link__text"}*/}
      {/*        to={"/handbook"}*/}
      {/*        onClick={() => onChange()}*/}
      {/*      >*/}
      {/*        Справочник*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*)}*/}
    </>
  );
}

export default MenuRouting;
