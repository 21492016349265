import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useNotification,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText, Input,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";

function ModalSiteShopOrderItemEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/site-shop-order-item/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputValue = useRef(null);
  const inputQuantity = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const quantityConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(6)).replace(".", ""))
      : null;
  };

  const [tumbler, setTumbler] = useState("ac");

  const changeEdit = (event) => {
    event.preventDefault();

    let quantity;

    if (tumbler === "aa") {
      const value = inputValue.current.value;
      const v = Number((Number(1).toFixed(6) * (!!object.nomenclature.value && !!object.nomenclature.measurement_unit_type ?
        (object.nomenclature.value / object.nomenclature.measurement_unit_type.ratio) : !!object.nomenclature.value && object.nomenclature.value)).toFixed(3))
      quantity = Number(Number(value) / v  ).toFixed(6)

    } else {
      quantity = inputQuantity.current.value
    }


    if (isValidateNullText(quantity)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      quantity: quantityConvert(quantity),
    });

    axios
      .put("/api/site-shop-order-item/" + props.object.uuid + "/edit/quantity", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/site-shop-order-item/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Заказ удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {object.nomenclature.name}
              <div className="Text__signature">
                №{object.site_shop_order.id}
                {!!object.site_shop_order.date && ' от ' + moment(object.site_shop_order.date.substring(0, 19))
                  .format("DD.MM.YYYY HH:mm")}
              </div>
            </div>

            <div className="Form__field">
              <ul
                className={styleTumbler._}
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <li className={styleTumbler._link}>
                  <a
                    className={
                      styleTumbler._text +
                      ((!!tumbler &&
                          tumbler === "ac" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={() => setTumbler("ac")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Количество
                  </a>
                </li>
                <li className={styleTumbler._link}>
                  <a
                    className={
                      styleTumbler._text +
                      ((!!tumbler &&
                          tumbler === "aa" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={() => setTumbler("aa")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Размер
                  </a>
                </li>
              </ul>
            </div>

            {!!tumbler && tumbler === "aa" && (
              <div className="Form__field Form__field_last">
                <Input reference={inputValue} type={'number'} label={"Размер (" +
                  (!!object.nomenclature.measurement_unit_type ? object.nomenclature.measurement_unit_type.designation : "-") + ") *"}
                       value={
                         Number((Number(object.quantity / 1000000).toFixed(6) * (!!object.nomenclature.value && !!object.nomenclature.measurement_unit_type ?
                           (object.nomenclature.value / object.nomenclature.measurement_unit_type.ratio) : !!object.nomenclature.value && object.nomenclature.value)).toFixed(3))
                       }
                />
              </div>
            )}

            {!!tumbler && tumbler === "ac" && (
              <div className="Form__field Form__field_last">
                <Input reference={inputQuantity} type={'number'} label="Количество *"
                       value={
                         (!!object.quantity && object.quantity / 1000000) || null
                       } />
              </div>
            )}

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              {/*<div className="Action__link" onClick={() => props.handleClose()}>*/}
              {/*  Отмена*/}
              {/*</div>*/}
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {object.nomenclature.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalSiteShopOrderItemEdit;
