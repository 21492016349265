import React, {useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, Modal, useNotification, useObject,} from "@vokymlak/kabinet-ui";

function ModalPrinterFileEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/printer-file/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: response => {
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const {notification} = useNotification();

  const changeEdit = () => {

    const jsonData = JSON.stringify({
      // name: name,
    });

    axios
      .put("/api/printer-file/" + props.object.uuid + "/print", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          <div className="Form">
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {!!object.name && object.name}
              <div className="Text__signature">Файл</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue"
                      onClick={() => changeEdit()}>
                Печать
              </button>
            </div>
          </div>
        </Modal>
      </>
    )
  );
}

export default ModalPrinterFileEdit;
