import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../config/url";
import {useList, useAction, useUnroll, useNotification,getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import axios from "axios";

function usePrinters() {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/printers",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction(["printers"], () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "printers"]);
    } else {
      setAction(["printers"]);
    }
  }, [list]);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  return {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,
  };
}

export default usePrinters;
